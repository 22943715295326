.igx-tabs__header-item{
  padding-bottom: 8px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 8px;
}

.igx-tabs__header-item--selected {
  color:#000000;
}

.igx-tabs__header-active-indicator {
  background:#000000;
}

.igx-tabs__header-item--selected igx-icon {
  color:#000000;
}

.igx-typography .igx-tabs__header-item-inner > [igxtabheaderlabel] {
  font-size:8pt;
}

.igx-tabs__panel {
  padding: 1rem;
}

.navbar-sub-button{

}


.ntp-grey {
  color: #b3b3b3 !important;
  height:60px !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-top: 0 !important;
  border-bottom-color: #000000;
  border-bottom-style: solid;
  border-bottom-width: 4px;
}


.ntp-white {
  color: #E4E4E4;
  padding-left: 7px !important;
  padding-right: 7px !important;
  font-size: 13px !important;
  border: 0 !important;
  height:40px !important;
  border-bottom-color: #000000;
  border-bottom-style: solid;
  border-bottom-width: 3px;
}

.navbar-main-active {
  border-radius: 0;
  border-bottom-color: #00F000;
  border-bottom-style: solid;
  border-bottom-width: 4px;
  color: #FFFFFF !important;
}

.navbar-sub-active{
  border-radius: 0 !important;
  border-bottom-color: #00F000 !important;
  border-bottom-style: solid !important;
  border-bottom-width: 3px !important;

}
