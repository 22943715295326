.igx-tabs__header-item{
  padding-bottom: 8px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 8px;
}

.igx-tabs__header-item--selected {
  color:#000000;
}

.igx-tabs__header-active-indicator {
  background:#000000;
}

.igx-tabs__header-item--selected igx-icon {
  color:#000000;
}


.igx-typography .igx-tabs__header-item-inner > [igxtabheaderlabel] {
  font-size:8pt !important;
}

.igx-tabs__panel {
  padding: 0;
  overflow: hidden;

}

.igx-tabs__header-item--selected:hover, .igx-tabs__header-item--selected:focus {
  background: #FFFFFF;
}

.tab-heading{
  font-weight: 700;
}

.tab-padding{
  padding-left: 10px;
  padding-top: 10px;
  padding-right: 5px;
}

.igx-tree-node__wrapper{
  padding-left: 1px;
  padding-right: 3px;
  min-height: 0;
}

.igx-tree-node__toggle-button{
  margin-inline-end: 1px;
}

.igx-typography .igx-tree-node__content{
  letter-spacing: 0px;
}

.igx-tree-node__toggle-button--hidden {

}


.igx-tree-node__spacer{
  width: 10px;
}

.igx-tree-node__wrapper--active {
  box-shadow: inset 0 0 0 1px #00F000
}

.igx-tree-node__wrapper--focused {
  box-shadow: inset 0 0 0 1px #00F000
}

.igx-tabs__header-item--selected:hover, .igx-tabs__header-item--selected:focus {
  color:#00A346;
}

.igx-tabs__header-item--selected:hover igx-icon, .igx-tabs__header-item--selected:focus igx-icon {
  color:#00A346;
}
