/* You can add global styles to this file, and also import other style files */

// Standard CSS normalize, comment out if not required or using a different module
@use "~minireset.css/minireset";
@use "@infragistics/igniteui-angular/theming" as *;

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@include core();
@include typography($font-family: "Montserrat", $type-scale: $fluent-type-scale);


@include core();
@include typography();
@include light-theme($light-material-palette);

@import url("https://fonts.googleapis.com/css?family=Montserrat:400");

/*
$custom-palette: palette(
  $primary: #00F000,
  $secondary: #00A346,
  $error: #a80000,
  $success: #00A346,
  $warn: #797673);
@include theme($palette: $custom-palette, $schema: $light-fluent-schema, $elevation: false, $roundness: 0);
*/

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat' !important;
  font-style: normal;
  font-weight: 400;

  // src: local('Montserrat'),
  //      url('assets/fonts/montserrat-v25-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  //      url('assets/fonts/montserrat-v25-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  //      url('assets/fonts/montserrat-v25-latin-regular.woff') format('woff'), /* Modern Browsers */
  //      url('assets/fonts/montserrat-v25-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  //      url('assets/fonts/montserrat-v25-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}

body{
  font-family: 'Montserrat' !important;
}

.igx-typography p{
  font-family: 'Montserrat' !important;
}

igx-pie-chart {
  --brushes: var(--pie-chart-brushes, rgb(0, 163, 70), rgb(189, 255, 187), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --outlines: var(--pie-chart-outlines, rgb(0, 0, 0), rgb(0, 0, 0), rgb(0, 0, 0), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --label-extent: var(--pie-chart-label-extent, 30);
  --label-outer-color: var(--pie-chart-label-outer-color, hsla(var(--igx-grays-700), var(--igx-grays-a)));
}

igx-data-chart {
  --title-text-color: var(--data-chart-title-text-color, hsla(var(--igx-grays-900), var(--igx-grays-a)));
  --subtitle-text-color: var(--data-chart-subtitle-text-color, hsla(var(--igx-grays-600), var(--igx-grays-a)));
  --brushes: var(--data-chart-brushes, rgb(0, 163, 70), rgb(189, 255, 187), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --marker-brushes: var(--data-chart-marker-brushes, rgb(0, 0, 0), rgb(0, 0, 0), rgb(0, 0, 0), rgb(0, 0, 0), rgb(0, 0, 0), rgb(0, 0, 0), rgb(0, 0, 0), rgb(0, 0, 0), rgb(0, 0, 0), rgb(0, 0, 0));
  --outlines: var(--data-chart-outlines, rgb(0, 0, 0), rgb(0, 0, 0), rgb(0, 0, 0), rgb(0, 0, 0), rgb(0, 0, 0), rgb(0, 0, 0), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
  --marker-outlines: var(--data-chart-marker-outlines, rgb(157, 231, 114), rgb(139, 91, 177), rgb(109, 177, 255), rgb(154, 242, 228), rgb(238, 88, 121), rgb(115, 86, 86), rgb(247, 210, 98), rgb(168, 168, 183), rgb(224, 81, 169), rgb(248, 161, 95));
}

.ui-chart-palette-1
{
    border-color: rgb(35, 128, 168);
    border-color: rgba(35, 128, 168, .8);
    background-color: rgb(68, 172, 214);
    background-color: rgba(68, 172, 214, .8);
}
.ui-chart-palette-2
{
    border-color: rgb(51, 51, 51);
    border-color: rgba(51, 51, 51, .8);
    background-color: rgb(73, 73, 73);
    background-color: rgba(73, 73, 73, .8);
}
.ui-chart-palette-3
{
    border-color: rgb(128, 128, 128);
    border-color: rgba(128, 128, 128, .8);
    background-color: rgb(168, 168, 168);
    background-color: rgba(168, 168, 168, .8);
}
.ui-chart-palette-4
{
    border-color: rgb(24, 81, 112);
    border-color: rgba(24, 81, 112, .8);
    background-color: rgb(33, 110, 153);
    background-color: rgba(33, 110, 153, .8);
}
.ui-chart-palette-5
{
    border-color: rgb(135, 153, 34);
    border-color: rgba(135, 153, 34, .8);
    background-color: rgb(164, 186, 41);
    background-color: rgba(164, 186, 41, .8);
}


.igx-tree-node__wrapper{
  line-height: 10px;
}

.igx-typography .igx-tree-node__content{
  font-family: 'Montserrat' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 9px;
  line-height: 12px;
}

.tree-root {
  width: auto !important;
  max-height: -moz-fit-content;
  max-height: fit-content !important;
}


.sample-wrapper{
  max-height: 100vh;
  overflow-y: auto;
}

.node-title{
  margin-left: 0px !important;
}


$item-active-color: #00F000;

.prio-button{
  //color: #FF9105 !important;
}


// Checkbox

.igx-checkbox {
  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
}

.igx-checkbox--indeterminate .igx-checkbox__composite, .igx-checkbox--checked .igx-checkbox__composite {
  color: #000000 !important;
  background: #00F000 !important;
  border-color: #00A346 !important;

}

.igx-checkbox--indeterminate .igx-checkbox__composite::after, .igx-checkbox--checked .igx-checkbox__composite::after {
  color: #000000 !important;
  background: #00F000 !important;
  border-color: #00A346 !important;
}

.igx-checkbox__composite {
  background: #FFFFFF !important;
}

.igx-checkbox--checked .igx-checkbox__composite-mark{
  background: #00F000 !important;

}


// Radio

.igx-radio--checked:hover .igx-radio__composite::before, .igx-radio--checked:active .igx-radio__composite::before {
  background: #00F000 !important;
  border-color: #00A346 !important;
}

.igx-radio--checked .igx-radio__composite::before {
  background: #00F000 !important;
  border-color: #00A346 !important;
}

.igx-radio--checked .igx-radio__composite::after {
  background: #FFFFFF !important;
  border-color: #00A346 !important;
}

// Switch
.igx-switch--checked .igx-switch__composite {
  background:  #00F000 !important;
}

.igx-switch--checked .igx-switch__composite-thumb {
  background:  #727272 !important;
}

// Treeview
.igx-tree-node__wrapper--active




body {
  background: hsla(var(--igx-surface-500));
  color: var(--igx-surface-500-contrast);
}

html, body {
  height: 100%;
  min-height: 100%;
  min-height: 100vh;
  font-family: 'Montserrat' !important;
}

.container_content {
  width: 100%;
  height: 100%;
  margin: auto;
}

.leftmenu {
  width: 376px;
  height: -webkit-fill-available;
  float: left;
  border-right-color: #7F7F7F !important;
  border-right-width: 3px !important;
  border-right-style: solid !important;
}

.main {
  margin-left: 376px;
  height: 100%;
  padding: 25px;
}



.igx-typography {
  h1, h2, h3, h4, h5, h6, p, .igx-typography__body-1 {
    margin: 0;
  }
}

.outer-wrapper > *:not(router-outlet) {
  width: 100%;
}

.igx-navbar{
  box-shadow:none;
  min-height: 40px;
}

.navbar-main, .navbar-main .igx-navbar {
  background-color: #000000;
  height: 60px;
  padding: 0 1rem;
}

.navbar-sub, .navbar-sub .igx-navbar {
  background-color: #727272;
  height: 40px;
  padding: 0 1rem;
}

.navbar-title-menu{
  //margin-top: 100px;
  display: flex;
  justify-content: center;
}


.igx-button .igx-icon
 {
  color:#b3b3b3;
}

.igx-button:hover {
  color:#FFFFFF;
}


.div-right
{
  margin-top: 30px;
  margin-bottom: 30px;
}

.button-green
 {
  background: #00A346;
  color: #FFFFFF;
}


.button-green[disabled=disabled], .button-green:disabled {
  background: #BFBFBF;
  color: #727272;

}

.button-green:hover, .button-green:focus
 {
  background-color: #BFBFBF;
  color: #727272;
}




table, th, td {
  border: 0px solid black;
  width:300;
}

.igx-input-group__bundle{
  padding-top: 0 !important;
  box-shadow: none !important;
}

.igx-input-group__input{
  font-size: 14px !important;
  color: #00A346;
  font-weight: 500 !important;
  padding-left: 0;
}

.action-bar {
  height:40px;
  padding:10px;
  width:100%;
  background-color: #FFFFFF;
}

.info-bar {
  width:100%;
  margin-bottom: 10px;
  background-color: #FFFFFF;
}

.info-bar-heading{
  font-weight: 700;
}

.info-bar-panel{
  width: 600 !important;
}

.heading-bar-text{
  font-size:22px;
  font-weight: 500;
  color:#212529;
}

.igx-grid{
box-shadow: none !important;
}

.group_1 {
  border-right-color: #7F7F7F;
  border-right-width: 3px;
  border-right-style: solid;
}

.view-container {
  padding-top:15px;
  padding-left:15px;
}



.info-table-head {
  font-weight: 600;
  text-align: left;
  padding-bottom: 2px;
  padding-left: 0;
  padding-right: 10px;
  padding-top: 2px;
  border-bottom-color: #BFBFBF;
  border-bottom-width: 2px;
  border-bottom-style: solid;
}

.info-table-text {
 text-align: left;
 padding-bottom: 2px;
 padding-left: 0;
 padding-right: 15px;
 padding-top: 2px;
 white-space: nowrap;
}

thead > tr > th.pvtTotalLabel, .rowTotal, .pvtGrandTotal { display: none; }

tbody > tr > th.pvtTotalLabel, .colTotal, .pvtGrandTotal { display: none; }

.pvtAxisLabel {
  background-color: #FFFFFF !important;
  color:#000000 !important;
  text-align: center !important;
  visibility: hidden;
}

//*[@id="output"]/table/thead/tr[1]/th[1]



table.pvtTable thead tr th:first-child {
  color: #000000;
  background-color: #FFFFFF;
  border: 1px solid #727272;
  font-size: 7pt;
  padding: 3px;
}

table.pvtTable thead tr th, table.pvtTable tbody tr th {
  color: #000000;
  background-color: #E6E6E6;
  border: 1px solid #727272;
  font-size: 7pt;
  padding: 3px;
  font-weight: 600;
}

table.pvtTable tbody tr td {
  color: #000000;
  padding: 3px;
  font-weight: 600;
  background-color: #FFFFFF;
  border: 1px solid #727272;
  font-size: 7pt;
  vertical-align: top;
  text-align: center;
}


table.pvtTable{
  -webkit-box-shadow: 3px 3px 2px 0px #BFBFBF;
  box-shadow: 3px 3px 2px 0px #BFBFBF;
}

table.pvtTable{
  -webkit-box-shadow: 3px 3px 2px 0px #BFBFBF;
  box-shadow: 3px 3px 2px 0px #BFBFBF;
}






.igx-drop-down__item--selected.igx-drop-down__item {
  background-color:#00A346;
}

.igx-drop-down__item--selected.igx-drop-down__item--hover {
  background-color:#00A346;
}

.igx-drop-down__item--selected.igx-drop-down__item--focused {
  background-color:#00A346;
}

.select-wrapper{
  margin-top:15px;
  margin-bottom:5px;
}

.igx-typography .igx-tabs__header-item-inner > [igxtabheaderlabel] {
  font-size:8pt !important;
}


// Modal
.igx-overlay__wrapper--modal{
  background: #727272;
  opacity:1.0;
  align-items: unset;
}

.igx-overlay__content--modal{
  background-color: #FFFFFF;
  border-width: 3px;
  border-style: solid;
  border-color:#00A346;
  width:1200px;
  height:750px;
  opacity:1 !important;

}

.igx-dialog, .igx-dialog__window {
  opacity:1 !important;
  height: 100% !important;
}

.igx-dialog__window-content {
  height:600px;
}




// Main table

table.main_table thead tr th{
color: #000000;
background-color: #BFBFBF;
border: 1px solid #727272;
font-size: 7pt;
padding: 3px;
font-weight: 600;
}

.table-focus-header {
  background-color: #BDFFBB !important;
}

.table-corner-header{
  background-color: #FFFFFF !important;
  border-top: 1px solid #FFFFFF !important;
  border-left: 1px solid #FFFFFF !important;
}


table.main_table tbody tr td {
  color: #000000;
  padding: 3px;
  font-weight: 600;
  background-color: #FFFFFF;
  border: 1px solid #727272;
  font-size: 7pt;
  vertical-align: top;
  text-align: center;
}


table.pvtTable {
  box-shadow: 3px 3px 2px 0px #bfbfbf;
}






:host {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
}

.container-app {
  height:  calc(100% - 100px);;
}




.navbar {
  height: max-content;
  min-width: min-content;
}
.view-container {
  position: relative;
  min-width: 0;
  min-height: 0;
  flex-grow: 1;
  flex-basis: 0;
}
.button {
  height: max-content;
  min-width: min-content;
}
.text {
  margin: 0 0 16px;
  height: max-content;
  min-width: min-content;
}
.hyperlink {
  margin: 0 0 8px;
  height: max-content;
  min-width: min-content;
}
.row-layout {
  display: flex;
}
.group {
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  position: relative;
  min-width: 50px;
  min-height: 50px;
  flex-grow: 1;
  flex-basis: 0;
}
.column-layout {
  display: flex;
  flex-direction: column;
}
.group_1 {
  background-color: hsla(var(--igx-grays-100));
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  padding: 0;
  width: 20%;
  min-width: 50px;
  min-height: 50px;
}

// PrimeNG OrgChart
a.p-node-toggler {
  display:none !important;
}

.p-organizationchart .p-organizationchart-line-top {
  border-color:#000000;
}

.p-organizationchart .p-organizationchart-line-down {
  border-color:#000000;
  background: #000000;
}

.p-organizationchart .p-organizationchart-line-left {
  border-color:#000000;
}

.p-organizationchart .p-organizationchart-line-right {
  border-color:#000000;
}


.dashboard-area {
  margin-top: 10px;
}

// Chart

.chart-title{
  text-align: center;
}

.chart-title-text{
//  border-bottom-style: solid;
//  border-bottom-width: 3px;
//  border-bottom-color: #BFBFBF;
}

.stat-card{
  width: 150px;
  height: 40px;
  margin: 0 auto;
  font-size: 22px;
  line-height: 40px;
  text-align: center;
  color:#000000;
  background-color: #FFFFFF;
  padding: 0;
  box-shadow: 0px 4px 8px rgba(191, 191, 191, 1);
  border-radius: 0.5rem;
  margin-top: 5px;
  margin-bottom: 5px;
}


// Slider

.igx-slider__track-fill {
  background-color: #00A346;
}

.igx-slider__track {
  background-color: #00A346;
}

.igx-slider-thumb-from__dot::before, .igx-slider-thumb-to__dot::before {
  background-color: #000000;
}

// Chart

.chart_show{
  display:block !important;
}

.chart_hide{
  display:none !important;
}

// Penetration Warning
.penetration-warning {
  background-color: #FFFFFF;
  border-color: #FF6E08;
  border-width: 2px;
  border-style: solid;
  font-size: 16px;
  width: 200px;
  font-weight: 600;
  text-align: center;
}

.penetration-warning-wrapper{
  height: 50px;
}

.penetration-warning {
  float: left;
}

.penetration-warning-switch{
  float: left;
  margin-left: 20px;
  height: 40px;
  padding-top: 2px;
}

// Legende

.legend-custom {
  margin-top: 5px !important;
  padding: 2px;
  border-width: 2px;
  border-style: solid;
  border-color: #727272;
  text-align: center;
}





// Chart

.legend {
  //width: fit-content;
  //width: 300px;
}

.ig-legend {
  width: -moz-fit-content;
  width: fit-content;
  //width: 300px;
  padding: 2px;
  text-align: left;
  border-style: solid;
  border-width: 2px;
  border-color: #BFBFBF;
}

.igx-item-legend {
  width: auto;
  //width: 300px;
  padding: 2px;
  text-align: left;
  border-style: solid;
  border-width: 2px;
  border-color: #BFBFBF;
}

.ig-chart-legend-items-list{

}


// Color Picker

.color-picker{
  position: static !important;
}

.setting-content{
  width: 1200px;
  margin: 0 auto;
}

.ChartMasterNavigation{
  width:600px;
  height:75px;
  padding:15px;
  margin-bottom: 10px;
  border-width: 5px;
  border-style: solid;
  border-color: #00A346;
  background-color: #E6E6E6;
}

.ChartMasterNavigation_Slide{
  float:left;
  margin-right: 20px;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
}

.ChartMasterNavigation_Button{
  float:left;
  padding-right: 20px;
}

.ChartMasterNavigation_Button_Cancel{
  margin-left: 30px;
  background-color: #727272;
}

.setting-tab-content{
  margin-top: 20px;
}

.igx-drop-down__group label{
  color:  #00A346;
}

.color-picker .type-policy{
  visibility: hidden;
}

